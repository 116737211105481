import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddOnsState, selectPlanState } from '@/state/selectors'
import useQueryString from './useQueryString'
import { parseQueryString } from '@/utils'
import { setAddOns, setInterstitialAddOns, setPostEnrollmentAddOn } from '@/state/addOns'

const useAddOns = () => {
  const dispatch = useDispatch()
  const { queryObject = {} } = useQueryString()
  const { addOnCodes, interstitialAddOnCodes } = parseQueryString(queryObject)
  const { details: { benefits, addOnInterstitialPagePosition } = {} } = useSelector(selectPlanState)
  const { isInitialized } = useSelector(selectAddOnsState)

  useEffect(() => {
    if (isInitialized || !benefits) {
      return
    }

    if (addOnInterstitialPagePosition && addOnInterstitialPagePosition.includes('BEFORE_ENROLLMENT')) {
      dispatch(setInterstitialAddOns({ benefits, interstitialAddOnCodes }))
    }
    if (addOnInterstitialPagePosition && addOnInterstitialPagePosition.includes('ENROLLMENT_UPGRADE')) {
      dispatch(setPostEnrollmentAddOn({ benefits }))
    }
    if (!!addOnCodes || !!interstitialAddOnCodes) {
      dispatch(setAddOns({ benefits, addOnCodes, interstitialAddOnCodes }))
    }
  }, [isInitialized, addOnCodes, benefits])
}

export default useAddOns

import React, { FunctionComponent, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch, { Case } from '@/components/Switch'
import { Button, Card, Dialog, Grid, TextField, Theme, useMediaQuery } from '@material-ui/core'
import { EnrollmentFormID, Images, Labels, TERMS_URL } from '@/constants'
import theme, {
  errorBackgroundColor,
  errorColor,
  fontSecondary,
  promoCodeAlertBackgroundColor,
  successColor,
  titleColor,
  titleFontSize,
} from '@/theme'
import { AddOn, Coupon, EnrollmentStep } from '@/state/types'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { selectEnrollmentState, selectPlanState, selectActivationState } from '@/state/selectors'
import { format } from 'date-fns'
import { cachePromoCodeInfo } from '@/state/enrollment'
import { Alert } from '@material-ui/lab'
import { limitPromoCodeLength } from '../../utils/fieldLengthValidator'
import { isNoAccount } from '@/utils'
import dynamic from 'next/dynamic'

const SubmitButton = dynamic(() => import('@/components/SubmitButton'))
const SkeletonDesktop = dynamic(() => import('./SkeletonDesktop'))
const FEATURES_DISPLAY_LENGTH = 3

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 24px',
    marginTop: '-60px',
    borderRadius: '12px',
    border: 'none',
    boxShadow: '0 0 28px 0 rgba(59, 71, 81, 0.10)',
    background: '#FFF',
    '& .MuiFormControl-marginNormal': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  cartTotal: {
    fontWeight: 'bold',
    paddingTop: '16px',
    fontFamily: fontSecondary,
    width: 'auto',
    color: theme.palette.common.black,
  },
  header: {
    margin: '0 -20px 14px -20px',
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
    margin: '0 20px 14px 20px',
    fontFamily: fontSecondary,
    fontSize: '20px',
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    margin: '12px 25px 12px 20px',
    letterSpacing: '.5px',
  },
  planDetailsContainerAutoEnroll: {
    marginBottom: '-10px',
  },
  planDetailsContainer: {
    borderBottom: '1px solid #CADBE5',
    padding: '0',
  },
  planDetails: {
    fontWeight: 'bold',
    marginBottom: '16px',
    position: 'relative',
    gap: '24px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    fontFamily: theme.typography.h2.fontFamily,
  },
  listItems: {
    color: titleColor,
    margin: '0',
    padding: '0',
    listStyle: 'none',
    fontSize: '16px',
    fontWeight: 400,
  },
  addOnDesc: {
    color: titleColor,
    margin: '10px 0 0 0',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  renewalText: {
    fontSize: '12px',
    margin: '10px 0',
    textAlign: 'right',
    '& span': {
      textDecoration: 'line-through',
      color: '#F72828',
    },
  },
  icon: {
    margin: '0 8px 0 0',
  },
  discountAmountIcon: {
    margin: '0 7px 0 0',
  },
  benefit: {
    display: 'flex',
    color: '#3B4752',
    fontFamily: fontSecondary,
    fontWeight: 500,
    alignItems: 'center',
    lineHeight: 'normal',
    marginTop: '16px',
    '&:first-child': {
      marginTop: '10px',
    },
  },
  amount: {
    textAlign: 'right',
    display: 'inline',
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    color: '#3B4752',
    fontFamily: fontSecondary,
  },
  discountAmount: {
    color: '#35825C',
    fontFamily: fontSecondary,
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  trialAmount: {
    '& + div': {
      color: 'rgba(59, 71, 82, 0.80)',
      fontFamily: fontSecondary,
      fontSize: '12px',
      fontWeight: 500,
      textAlign: 'right',
    },
  },
  totalAmount: {
    fontSize: '18px',
    fontFamily: fontSecondary,
    color: theme.palette.text.secondary,
    textAlign: 'right',
  },
  removeDiscountButton: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: 0,
  },
  planName: {
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: '8px',
    border: '0',
    background: 'none',
    padding: '0',
    fontSize: '16px',
    fontFamily: fontSecondary,
    '& span img': {
      marginLeft: '7px',
      verticalAlign: 'middle',
    },
    '& + button': {
      marginLeft: '12px',
    },
  },
  detailsModal: {
    '& .MuiPaper-root': {
      padding: '24px 16px',
      width: '340px',
      color: titleColor,
      fontSize: '16px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '12px',
    },
  },
  detailsModalTitle: {
    fontSize: '20px',
    color: theme.palette.text.secondary,
    padding: '0 0 10px 0',
    margin: '0',
    width: '285px',
    lineHeight: '22px',
    borderBottom: '1px solid #CADBE5',
  },
  detailsModalClose: {
    position: 'absolute',
    right: '20px',
    top: '22px',
    width: '26px',
    height: '26px',
    background: 'none',
    border: '0',
    padding: '0',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '3px',
      width: '18px',
      height: '2px',
      background: '#3B4752',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '18px',
      height: '2px',
      right: '5px',
      background: '#3B4752',
      transform: 'rotate(135deg)',
    },
  },
  couponSubmitButton: {
    borderRadius: '4px',
    background: 'rgba(59, 71, 82, 0.50)',
    padding: '12px 24px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: fontSecondary,
    color: '#FFF',
    height: '42px',
    textTransform: 'none',
    minWidth: 'auto',
  },
  submitMobileContainer: {
    margin: '0 0 24px 0',
  },
  couponWrap: {
    borderTop: '1px solid #CADBE5',
    borderBottom: '1px solid #CADBE5',
    marginBottom: '16px',
  },
  couponContainer: {
    display: 'flex',
    gap: '16px',
    padding: '10px 0',
    alignItems: 'center',
  },
  couponField: {
    '& input': {
      background: '#FFF',
      borderRadius: '4px',
      height: '42px',
      boxSizing: 'border-box',
      color: 'rgba(59, 71, 82, 0.80)',
      fontWeight: 500,
      letterSpacing: 'initial',
      fontFamily: fontSecondary,
      fontSize: '14px',
      '&::placeholder': {
        color: 'rgba(59, 71, 82, 0.80)',
        opacity: 1,
      },
    },
    '& fieldset': {
      borderRadius: '5px',
      overflow: 'visible',
      borderWidth: '1px !important',
      border: '1px dashed #B2B2B2',
    },
    '& fieldset:before': {
      content: '""',
      width: '30px',
      height: '20px',
      top: '-14px',
      left: '-7px',
      position: 'absolute',
      background: 'url(images/clips.svg) no-repeat center center',
    },
  },
  discountsContainer: {
    borderTop: '1px solid #CADBE5',
    borderBottom: '1px solid #CADBE5',
    gap: '16px',
    padding: '16px 0',
    flexFlow: 'column',
    marginBottom: '16px',
  },
  discountItem: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: fontSecondary,
    fontWeight: 500,
    padding: '9px 2px 9px 9px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.secondary.main}`,
    '& > div': {
      flex: 1,
      fontSize: '12px',
      color: titleColor,
    },
  },
  discountName: {
    color: theme.palette.secondary.main,
    fontSize: '14px',
  },
  promoCodeAlert: {
    paddingBottom: '20px',
    '& .MuiAlert-outlinedSuccess': {
      borderRadius: '8px',
      borderColor: successColor,
      backgroundColor: promoCodeAlertBackgroundColor,
      borderWidth: '2px',
      fontSize: titleFontSize,
      fontWeight: 'bold',
    },
    '& .MuiAlert-outlinedSuccess .MuiAlert-message': {
      color: successColor,
    },
    '& .MuiAlert-outlinedError': {
      borderRadius: '8px',
      borderColor: errorColor,
      backgroundColor: errorBackgroundColor,
      borderWidth: '2px',
      fontSize: titleFontSize,
      fontWeight: 'bold',
    },
    '& .MuiAlert-outlinedError .MuiAlert-message': {
      color: errorColor,
    },
  },
  renewalContainer: {
    borderRadius: '12px',
    background: process.env.THEME_NAME === 'CreditScoreIQ' ? '#F6ECFE' : '#0094FF1A',
    padding: '24px 24px 18px 24px',
    color: '#3B4752',
    lineHeight: 'normal',
    marginTop: '16px',
    '& h5': {
      fontSize: '16px',
      margin: '0',
      fontFamily: fontSecondary,
    },
    '& ul': {
      margin: '12px 0 0 0',
      paddingLeft: '20px',
      fontSize: '10px',
      fontWeight: 500,
      fontFamily: fontSecondary,
    },
    '& li': {
      padding: '6px 0 6px 6px',
    },
    '& li span': {
      fontSize: '14px',
    },
  },
  disclaimerLinks: {
    minWidth: 'auto',
    background: 'none !important',
    verticalAlign: 'baseline',
    fontSize: '16px',
    padding: '0',
    lineHeight: '17px',
    fontFamily: fontSecondary,
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  checkboxFormControlLabelText: {
    fontSize: '14px',
    lineHeight: 'normal',
    fontWeight: 600,
    margin: '16px 0',
    color: '#3B4752',
    fontFamily: fontSecondary,
  },
  discountAmountWrap: {
    marginLeft: 'auto',
  },
  discountPrices: {
    justifyContent: 'space-between',
    gap: '10px',
    flexWrap: 'nowrap',
  },
  interval: {
    fontSize: '12px',
  },
  termsError: {
    color: '#B12532',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    margin: '24px 0 0 0',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  trialInricator: {
    border: '1px solid #35825C',
    borderRadius: '4px',
    fontSize: '10px',
    lineHeight: 1,
    padding: '4px 8px',
    height: '20px',
    fontWeight: 600,
    fontFamily: fontSecondary,
    color: '#35825C',
  },
  viewButton: {
    color: process.env.THEME_NAME === 'CreditScoreIQ' ? theme.palette.secondary.light : theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: fontSecondary,
    background: 'none',
    border: 0,
    padding: 0,
    display: 'block',
    cursor: 'pointer',
    outline: 'none',
  },
  featuresContainer: {
    marginBottom: '20px',
    '& h4': {
      color: '#3B4752',
      fontFamily: fontSecondary,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '23px',
      margin: 0,
    },
    '& li': {
      color: 'rgba(59, 71, 82, 0.80)',
      fontFamily: fontSecondary,
      fontSize: '12px',
      margin: '2px 0',
    },
  },
}))

interface Props {
  planIsFetching: boolean
  planName: string
  planBenefits: string[]
  displayPrice: string
  defaultPrice: string
  billingInterval: string
  planPrice: string
  onReinstatePendingEnrollment: () => void
  appliedCoupon?: Coupon | null
  isAccountInfo?: boolean
  isOneTimePurchase?: boolean
  addOns: AddOn[]
  allAddOns: AddOn[]
  isTrial: boolean
  trialEnd: Date
  trialDays: number
  onToggleAddOn: (code: string) => void
}

const CartDesktop: FunctionComponent<Props> = ({
  planIsFetching,
  planName,
  planBenefits,
  defaultPrice,
  billingInterval,
  planPrice,
  onReinstatePendingEnrollment,
  appliedCoupon,
  isOneTimePurchase,
  addOns,
  isTrial,
  trialDays,
}: Props) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const [isShowBenefits, setIsShowBenefits] = useState(false)
  const [isFeatureOpen, setIsFeatureOpen] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const { billing, step } = useSelector(selectEnrollmentState)
  const { details } = useSelector(selectPlanState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const isAutoEnroll = activationDetails?.memberInfo.orderSource === 'AUTOENROLL'
  const isNoAccountPlan = isNoAccount(details)
  const isShowRenewalText = !isOneTimePurchase && !isNoAccountPlan && step === EnrollmentStep.BillingInfo
  appliedCoupon = billing.appliedCoupon

  const price = parseFloat(planPrice)
  const itemPrice = parseFloat(defaultPrice)
  const originalPrice = itemPrice > price ? itemPrice : price
  const isCouponApplied = appliedCoupon && appliedCoupon.couponPrice ? true : false
  let couponDiscountPrice = 0
  let couponPrice = 0
  const selectedAddOns = addOns.filter((item) => item.isSelected)
  const addOnsPrice = selectedAddOns.reduce(
    (acc: number, addOn: AddOn) => (acc += addOn.price > addOn.defaultPrice ? addOn.price : addOn.defaultPrice),
    0.0,
  )
  const addOnsDiscountPrice = selectedAddOns
    .filter((addOn: AddOn) => addOn.defaultPrice > addOn.price)
    .reduce((acc: number, addOn: AddOn) => (acc += addOn.defaultPrice - addOn.price), 0.0)
  const plandiscountAmount = originalPrice > price ? originalPrice - price : 0.0
  const discountAmount = addOnsDiscountPrice > 0 ? plandiscountAmount + addOnsDiscountPrice : plandiscountAmount
  const subTotalAmount = originalPrice + addOnsPrice
  const interval = billingInterval.split(' ')[1] ? billingInterval.split(' ')[1] : billingInterval
  const autoRenewalText = isTrial
    ? `By clicking “SUBMIT”, I agree to the above terms of the ${trialDays}-day trial and auto-renewing membership.`
    : 'By clicking “SUBMIT”, I agree to the auto-renewal terms.'

  if (isCouponApplied) {
    couponPrice = parseFloat(appliedCoupon?.couponPrice || '0')
    couponDiscountPrice = couponPrice + addOnsPrice - addOnsDiscountPrice
  }
  const totalAmount =
    (isCouponApplied ? couponPrice : originalPrice - plandiscountAmount) + (addOnsPrice - addOnsDiscountPrice)
  const couponList = details?.coupon || []
  const nextBillDate = new Date((isTrial ? details?.nextBillDate : details?.ignoreTrialNextBillDate) || new Date())
  const formattedNextBillDate = format(nextBillDate, 'LLLL d, yyyy')

  const renewalCopy = `Automatically renews at $${totalAmount.toFixed(2)}/ ${interval} on ${formattedNextBillDate}.`
  const hasDiscount = discountAmount || (appliedCoupon?.couponCode && appliedCoupon.isValid)

  const removeCoupon = () => {
    dispatch(cachePromoCodeInfo({ couponCode: null, isValid: true }))
    couponDiscountPrice = 0
  }

  const handleChangeCoupon = (e: React.ChangeEvent<HTMLInputElement>) => setCouponCode(e.target.value?.trim())

  const handleValidatePromoCode = (): void => {
    const coupon: Coupon = couponList.filter((coupon: Coupon) => coupon.couponCode === couponCode)[0]
    {
      dispatch(cachePromoCodeInfo({ ...coupon, couponCode: coupon ? coupon.couponCode : '', isValid: !!coupon }))
      setCouponCode('')
    }
  }

  return (
    <>
      {!isDesktop && step !== EnrollmentStep.BillingInfo && (
        <div className={classes.submitMobileContainer}>
          <SubmitButton form={EnrollmentFormID} onClick={onReinstatePendingEnrollment} />
        </div>
      )}
      <Card className={classes.root}>
        <div className={classes.header}>
          <p className={classes.heading}>{isAutoEnroll ? 'Your Plan' : 'Order Summary'}</p>
        </div>
        <Switch on={String(planIsFetching)}>
          <Case id={'true'} component={<SkeletonDesktop />} />
          <Case
            id={'false'}
            component={
              <>
                <div className={isAutoEnroll ? classes.planDetailsContainerAutoEnroll : classes.planDetailsContainer}>
                  <Grid container classes={{ root: classes.planDetails }}>
                    <Grid>
                      <div className={classes.planName}>
                        {planName}
                        {isTrial && <span className={classes.trialInricator}>Trial</span>}
                      </div>
                      {isShowRenewalText && (
                        <button type="button" className={classes.viewButton} onClick={() => setIsFeatureOpen(true)}>
                          View Features
                        </button>
                      )}
                    </Grid>
                    {isAutoEnroll ? (
                      <div />
                    ) : isOneTimePurchase ? (
                      <Grid className={classes.amount}>{`$${Number(details?.oneTimeFee).toFixed(2)}`}</Grid>
                    ) : (
                      <Grid className={classes.amount}>
                        {isTrial ? (
                          <>
                            <div className={classes.trialAmount}>{`${trialDays} Days for $${+Number(
                              details?.trialFee,
                            ).toFixed(2)}`}</div>
                            <div>
                              Then{' '}
                              <span className={hasDiscount ? classes.lineThrough : ''}>
                                {`$${subTotalAmount.toFixed(2)}`} /{details?.billingIntervalShort}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span className={hasDiscount ? classes.lineThrough : ''}>
                            {`$${subTotalAmount.toFixed(2)}`} /{details?.billingIntervalShort}
                          </span>
                        )}
                        {!!discountAmount && (
                          <Grid className={classes.discountAmount}>
                            <img
                              width="12"
                              height="13"
                              src={Images.IconCoupon}
                              alt=""
                              className={classes.discountAmountIcon}
                            />
                            <span
                              className={appliedCoupon?.couponCode && appliedCoupon.isValid ? classes.lineThrough : ''}
                            >
                              {`$${(subTotalAmount - discountAmount).toFixed(2)}`} /{details?.billingIntervalShort}
                            </span>
                          </Grid>
                        )}
                        {appliedCoupon?.couponCode && appliedCoupon.isValid && (
                          <Grid className={classes.discountAmount}>
                            <img
                              width="12"
                              height="13"
                              src={Images.IconCoupon}
                              alt=""
                              className={classes.discountAmountIcon}
                            />
                            {`$${couponDiscountPrice.toFixed(2)}`} /{details?.billingIntervalShort}
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>

                  {appliedCoupon?.couponCode && appliedCoupon.isValid && (
                    <Grid className={classes.discountsContainer}>
                      <Grid item className={classes.discountItem}>
                        <img width="24" height="24" src={Images.DiscountSuccessIcon} alt="" className={classes.icon} />
                        <div>
                          <div className={classes.discountName}>{appliedCoupon.couponCode} Applied</div>
                        </div>
                        <Button
                          className={cx(classes.removeDiscountButton, 'remove-coupon')}
                          onClick={() => removeCoupon()}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {details?.coupon && !(appliedCoupon?.couponCode && appliedCoupon.isValid) && (
                    <Grid className={classes.couponWrap}>
                      <div className={classes.couponContainer} onSubmit={() => handleValidatePromoCode()}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="couponCode"
                          name="couponCode"
                          className={classes.couponField}
                          placeholder={Labels.CouponCode}
                          onChange={handleChangeCoupon}
                          value={couponCode}
                          onInput={limitPromoCodeLength}
                        />
                        <Button
                          type="button"
                          variant="contained"
                          className={classes.couponSubmitButton}
                          onClick={() => handleValidatePromoCode()}
                        >
                          Apply
                        </Button>
                      </div>

                      {appliedCoupon && !appliedCoupon.isValid && (
                        <div>
                          <Grid item xs={12} className={classes.promoCodeAlert}>
                            <Alert variant="outlined" severity="error">
                              Invalid promo code
                            </Alert>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  )}

                  {!isShowRenewalText && (
                    <Grid className={classes.featuresContainer}>
                      <h4>Features</h4>
                      <ul className={classes.listItems}>
                        {planBenefits
                          ?.slice(0, isShowBenefits ? planBenefits?.length : FEATURES_DISPLAY_LENGTH)
                          .map((benefit) => (
                            <li key={benefit}>{benefit}</li>
                          ))}
                      </ul>
                      {(planBenefits?.length ?? 0) > FEATURES_DISPLAY_LENGTH && (
                        <button
                          type="button"
                          className={classes.viewButton}
                          onClick={() => setIsShowBenefits(!isShowBenefits)}
                        >
                          {!isShowBenefits ? 'More' : 'Less'}
                        </button>
                      )}
                    </Grid>
                  )}

                  {isFeatureOpen && (
                    <Dialog
                      open={isFeatureOpen}
                      onClose={() => setIsFeatureOpen(false)}
                      className={classes.detailsModal}
                    >
                      <button className={classes.detailsModalClose} onClick={() => setIsFeatureOpen(false)}></button>
                      <h4 className={classes.detailsModalTitle}>{planName} Includes:</h4>
                      <ul className={classes.listItems}>
                        {planBenefits.map((benefit) => (
                          <li key={benefit} className={classes.benefit}>
                            <img width="24" height="24" src={Images.PlanDetailsIcon} alt="" className={classes.icon} />
                            <span>{benefit}</span>
                          </li>
                        ))}
                      </ul>
                    </Dialog>
                  )}

                  {!isAutoEnroll && isShowRenewalText && (
                    <>
                      <div className={classes.renewalContainer}>
                        <h5>Automatic Renewal:</h5>
                        <ul>
                          <li>
                            <span>{renewalCopy}</span>
                          </li>
                          <li>
                            <span>
                              You will be charged this amount every {interval === 'biannual' ? '6 months' : interval}{' '}
                              until you cancel your membership.
                            </span>
                          </li>
                          {isTrial ? (
                            <>
                              <li>
                                <span>
                                  You can cancel at any time before {formattedNextBillDate}, in accordance with the{' '}
                                  <Button
                                    onClick={() => window.open(TERMS_URL, 'Cancellation Policy', 'resizable')}
                                    className={classes.disclaimerLinks}
                                  >
                                    cancellation policy
                                  </Button>
                                  , to avoid being charged.
                                </span>
                              </li>
                              <li>
                                <span>
                                  If you cancel before the end of a membership period, you will not be provided a
                                  partial refund.
                                </span>
                              </li>
                            </>
                          ) : (
                            <li>
                              <span>
                                You can cancel any time in accordance with the{' '}
                                <Button
                                  onClick={() => window.open(TERMS_URL, ' Cancellation Policy', 'resizable')}
                                  className={classes.disclaimerLinks}
                                >
                                  cancellation policy
                                </Button>
                                , but there are no refunds.
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className={classes.checkboxFormControlLabelText}>{autoRenewalText}</div>
                    </>
                  )}
                </div>

                {!isAutoEnroll && (
                  <Grid container classes={{ root: classes.cartTotal }}>
                    <Grid item xs={6}>
                      Total Due
                    </Grid>
                    <Grid item xs={6}>
                      {isOneTimePurchase ? (
                        <div className={classes.totalAmount}>{`$${Number(details?.oneTimeFee).toFixed(2)}`}</div>
                      ) : isTrial ? (
                        <div className={classes.totalAmount}>${details?.trialFee}</div>
                      ) : (
                        <div className={classes.totalAmount}>{`$${totalAmount.toFixed(2)}`}</div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            }
          />
        </Switch>
      </Card>
      {isDesktop && <SubmitButton form={EnrollmentFormID} onClick={onReinstatePendingEnrollment} />}
    </>
  )
}

export default CartDesktop

import React, { FunctionComponent } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { EnrollmentStep } from '@/state/types'
import { Hidden, StepConnector } from '@material-ui/core'
import { fontSecondary, stepperBorderColor, stepperColor } from '@/theme'
import { useSelector } from 'react-redux'
import { selectEnrollmentState, selectPlanState, selectActivationState } from '@/state/selectors'
import { isNoAccount } from '@/utils'

const isCXIQ = process.env.THEME_NAME === 'CreditScoreIQ' || process.env.THEME_NAME === 'CreditBuilderIQ'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperRoot: {
      padding: 0,
      background: 'transparent',
      maxWidth: '1100px',
      margin: '0 auto',
      '& .MuiStepIcon-root': {
        color: '#9DA3A8',
        width: 28,
        height: 28,
        overflow: 'initial',
      },
      '& .MuiStep-root': {
        color: '#666',
        '&.stepper-active': {
          opacity: '1',
          '& .MuiStepLabel-active': {
            color: theme.palette.common.black,
            fontWeight: isCXIQ ? 500 : 700,
          },
          '& .MuiStepIcon-root': {
            color: theme.palette.primary.main,
          },
        },
        '& .MuiStepIcon-completed': {
          color: '#65B920',
          '& .MuiStepIcon-root': {
            color: '#65B920',
          },
        },
        '& .MuiStepLabel-label': {
          color: '#666',
        },
        '& .MuiStepLabel-completed': {
          fontWeight: 400,
          color: '#383838',
        },
      },
      '& .stepper-active .MuiStepLabel-iconContainer div': {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiStepConnector-root': {
        left: 'calc(-50% + 14px)',
        right: 'calc(50% + 14px)',
      },
      '& *': {
        fontFamily: fontSecondary,
      },
      '& .MuiStepIcon-text': {
        fontFamily: fontSecondary,
        fontWeight: 600,
        transform: 'translateY(0.5px)',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '320px',
      },
    },
    stepperLabel: {
      color: stepperColor,
    },
    root: {
      width: '100%',
      padding: 0,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    dot: {
      height: '15px',
      width: '15px',
      borderRadius: '50%',
      border: stepperBorderColor,
      marginTop: '5px',
    },
    dotFilled: {
      background: stepperColor,
    },
    stepName: {
      textAlign: 'center',
      color: theme.palette.common.black,
      fontWeight: isCXIQ ? 500 : 700,
      fontFamily: fontSecondary,
      fontSize: theme.typography.body2.fontSize,
      margin: '-10px 0 10px 0',
      paddingTop: '8px',
    },
    stepperAlternativeLabel: {
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
      top: '14px',
    },
    stepperLineHorizontal: {
      borderTopWidth: '1px',
      borderColor: '#D6D6D6',
    },
  }),
)

type Props = {
  activeStep: EnrollmentStep
}

const ProgressStepper: FunctionComponent<Props> = ({ activeStep }: Props) => {
  const classes = useStyles()
  const { details } = useSelector(selectPlanState)
  const { isProveWorkflow } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)

  let steps =
    !details || details?.isPlanAuthRequired
      ? ['Create Your Account', 'Identity Information', 'Billing Information']
      : ['Create Your Account', 'Complete Enrollment']

  const isNoAccountPlan = isNoAccount(details)
  if (isNoAccountPlan && steps.length == 3) {
    steps.pop()
  }

  if (isProveWorkflow) {
    steps = !isNoAccountPlan
      ? ['Quick Sign Up', 'Confirm Your Information', 'Billing Information']
      : ['Quick Sign Up', 'Confirm Your Information']
  }

  if (activationDetails) {
    steps = ['Confirm Your Account']

    if (details?.isPlanAuthRequired) {
      const isAutoEnroll = activationDetails.memberInfo.orderSource === 'AUTOENROLL'
      steps.push(isAutoEnroll ? 'Complete Activation' : 'Complete Enrollment')
    }
  }

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <Typography variant="body1" className={classes.stepName}>
          {`${activeStep + 1}. ${steps[activeStep]}`}
        </Typography>
      </Hidden>
      <Stepper
        classes={{ root: classes.stepperRoot }}
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepConnector
            classes={{
              lineHorizontal: classes.stepperLineHorizontal,
              alternativeLabel: classes.stepperAlternativeLabel,
            }}
          />
        }
      >
        {steps.map((label, index) => (
          <Step key={label} className={`${index === activeStep && 'stepper-active'}`}>
            <StepLabel>
              <Hidden smDown>
                <span>{`${index + 1}. ${label}`}</span>
              </Hidden>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default ProgressStepper
